<template>
  <section class="test-vh">
    <h4 class="text-center p-4 m-4">TEST DE ORIENTACIÓN PROFESIONAL</h4>
    <b-container v-if="counter < 0" class="b-container-background sign-user_card mt-4">
      <b-row>
        <b-col cols="12" sm="4">
          <h5>
            PRIMERA ETAPA:<br /> ESCOGIENDO LO QUE MÁS TE GUSTA HACER
          </h5>
        </b-col>
        <b-col>
          <p>
            <strong>Instrucciones:</strong> A continuación se presenta un
            listado de actividades relacionadas con varios campos profesionales.
            Selecciona:
          </p>
          <p>
            <strong>SI:</strong> En el caso de que la actividad sugerida sea de
            tu agrado
          </p>
          <p><strong>NO:</strong> Si te resulta indiferente o te desagrada</p>
        </b-col>
      </b-row>
      <hr />
      <b-row>
        <b-col cols="12" sm="4">
          <h5>
            SEGUNDA ETAPA:<br /> VALORANDO TUS APTITUDES Y COMPETENCIAS
          </h5>
        </b-col>
        <b-col>
          <p>
            Califica de 1 a 5 tus habilidades en las seis áreas que se te
            presentan, de acuerdo al nivel en el que estimes te encuentras en
            cada una. Por cada grupo de habilidades, se ofrece una definición y
            varios ejemplos de las actividades en las que se ponen en práctica
            dichas destrezas. Contesta considerando tu nivel general de
            desempeño (no importa si sobresales en unas más que en otras) en
            cada área. Sigue esta escala:
          </p>
          <p>1. Muy bajo nivel de desarrollo</p>
          <p>2. Bajo nivel de desarrollo</p>
          <p>3. Medio nivel de desarrollo</p>
          <p>4. alto nivel de desarrollo</p>
          <p>5. Muy alto nivel de desarrollo</p>
        </b-col>
      </b-row>
      <hr />
      <b-row>
        <b-col cols="12" sm="4">
          <h5>
            TERCERA ETAPA:<br /> EXPLORANDO LAS OCUPACIONES QUE MÁS TE GUSTAN
          </h5>
        </b-col>
        <b-col>
          <p>
            En esta etapa se te presenta un listado de ocupaciones relacionados
            con varias carreras, de tipo técnico y universitario. Responde
            pensando en si la ocupación es o no de tu agrado, independientemente
            de que hayas considerado cursarla o no, así como del nivel de
            formación requerido.
          </p>
          <P>Selecciona:</P>
          <P>SI, cuando la ocupación sea de tu interés</P>
          <P>NO, cuando la ocupación te resulte indiferente o te desagrade</P>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <button v-if="this.userInfo.auth" id="start-test" class="btn btn-hover mt-3" v-on:click="counter += 1">
            Comenzar
          </button>
          <router-link v-else :to="{ name: 'auth1.sign-in1' }" class="btn btn-hover mt-3">ACCEDER</router-link>
        </b-col>
      </b-row>
    </b-container>

    <b-container v-if="counter < 30 && counter >= 0" class="b-container-background sign-user_card mt-4 ">
      <span class="type">ESCOGIENDO LO QUE MÁS TE GUSTA HACER</span>
      <b-row class="text-center mt-4 pt-4 pb-4">
        <b-col>
          <h5>
            {{ this.preguntas[this.counter].title }}
          </h5>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
        </b-col>
      </b-row>
      <b-row v-for="(question, index) in preguntas" :key="index">
        <b-col v-if="counter == index" class="text-center">
          <b-button :class="{ 'active': question.answer === 20 }" class="btn btn-circle"
            @click="question.answer = optionsV0[0].value; avanzar()">Sí</b-button>
        </b-col>
        <b-col v-if="counter == index" class="text-center">
          <b-button :class="{ 'active': question.answer === 0 }" class="btn btn-circle"
            @click="question.answer = optionsV0[1].value; avanzar()">No</b-button>
        </b-col>
      </b-row>
      <b-row align-h="between" class="mb-4 mt-4 text-center">
        <b-col cols="5" sm="4" md="3" lg="2" xl="2">
          <button class="btn btn-hover mt-3" v-on:click="counter -= 1" :disabled="!counter > 0">
            Anterior
          </button>
        </b-col>
        <b-col cols="5" sm="4" md="3" lg="2" xl="2">
          <button class="btn btn-hover mt-3" v-on:click="nextQuestion(preguntas[counter].answer)">
            Siguiente
          </button></b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-progress :max="100" v-if="counter >= 0 && counter < 42">
            <b-progress-bar :value="((counter / 42) * 100).toFixed()" :label="`${((counter / 42) * 100).toFixed()}%`">
            </b-progress-bar>
          </b-progress>
        </b-col>
      </b-row>
    </b-container>
    <!-- SESION 2 -->
    <b-container v-if="counter >= 30 && counter <= 35" class="b-container-background sign-user_card mt-4">
      <span class="type">VALORANDO TUS APTITUDES Y COMPETENCIAS</span>
      <b-row class="text-center mt-5 pt-4 pb-4">
        <b-col>
          <h5>
            {{ this.preguntas2[this.counter - 30].title }}
          </h5>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <p class="lead">
            {{ this.preguntas2[this.counter - 30].descripcion }}
          </p>
          <ul>
            <li v-for="(opcion, index) in this.preguntas2[
              this.counter - 30
            ].lista.filter(Boolean)" :key="index">
              <p>{{ opcion }}</p>
            </li>
          </ul>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
        </b-col>
      </b-row>
      <b-row v-for="(question, index) in preguntas2" :key="index">
        <b-col v-if="counter == index + 30" class="text-center">
          <b-form-select v-model="question.answer" :options="optionsV" @change="avanzar">
          </b-form-select>
        </b-col>
      </b-row>
      <b-row align-h="between" class="mb-4 mt-4 text-center">
        <b-col cols="5" sm="4" md="3" lg="2" xl="2">
          <button class="btn btn-hover mt-3" v-on:click="counter -= 1">
            Anterior
          </button>
        </b-col>
        <b-col cols="5" sm="4" md="3" lg="2" xl="2">
          <button class="btn btn-hover mt-3" v-on:click="nextQuestion(preguntas2[counter - 30].answer)">
            Siguiente
          </button></b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-progress :max="100" v-if="counter >= 0 && counter < 42">
            <b-progress-bar :value="((counter / 42) * 100).toFixed()" :label="`${((counter / 42) * 100).toFixed()}%`">
            </b-progress-bar>
          </b-progress>
        </b-col>
      </b-row>
    </b-container>
    <!-- SESION 3 -->
    <b-container v-if="counter >= 36 && counter <= 41" class="b-container-background sign-user_card mt-4">
      <span class="type">ELIGE LAS OCUPACIONES QUE MÁS TE LLAMEN LA ATENCIÓN</span>
      <b-row class="text-center mt-5 pt-4 pb-4">
        <b-col>
          <h5>
            {{ this.preguntas3[this.counter - 36].title }}
          </h5>
        </b-col>
      </b-row>
      <b-row class="m-2" v-for="question in preguntas3[this.counter - 36].preguntas" :key="question.id">
        <b-col cols="6">
          <h5 class="pregunta">{{ question.title }}</h5>
        </b-col>
        <b-col class="text-center" cols="3">
          <b-button :class="{ 'active': question.answer === 11.11 }" class="btn btn-circle"
            @click="question.answer = optionsV1[0].value;">Sí</b-button>
        </b-col>
        <b-col class="text-center" cols="3">
          <b-button :class="{ 'active': question.answer === 0 }" class="btn btn-circle"
            @click="question.answer = optionsV1[1].value;">No</b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
        </b-col>
      </b-row>
      <b-row align-h="between" class="mb-4 mt-4 text-center">
        <b-col cols="5" sm="4" md="3" lg="2" xl="2">
          <button class="btn btn-hover mt-3" v-on:click="counter -= 1">
            Anterior
          </button>
        </b-col>
        <b-col cols="5" sm="4" md="3" lg="2" xl="2">
          <button class="btn btn-hover mt-3"
            v-on:click="nextQuestion(!preguntas3[counter - 36].preguntas.some(pregunta => pregunta.answer === null))">
            Siguiente
          </button></b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-progress :max="100" v-if="counter >= 0 && counter < 42">
            <b-progress-bar :value="((counter / 42) * 100).toFixed()" :label="`${((counter / 42) * 100).toFixed()}%`">
            </b-progress-bar>
          </b-progress>
        </b-col>
      </b-row>
    </b-container>
    <!--RESULTADOS SEPARARLO EN UN NUEVO COMPONENTE Y REUTILIZARLO EN LA PAGINA DE RESULTADOS-->
    <b-container v-if="counter == 42" class="b-container-background sign-user_card mt-4">
      <b-row>
        <b-col class="text-center">
          <h5>PERFIL GLOBAL</h5>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <p>
            El cuestionario estima tus intereses, habilidades y las opciones
            profesionales disponibles en seis campos laborales. A continuación
            te presentaremos tus resultados:<br />
            Haz clic en el botón rojo de cada área para entender qué
            significa.
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <Resultado :resultados="resultados" />
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col cols="12" sm="12" md="6">
          <p class="lead">
            ES IMPORTANTE QUE TENGAS EN CUENTA QUE TODAS LAS CARRERAS TIENEN EN
            MAYOR O MENOR MEDIDA LOS SEIS TIPOS DE INTERESES:
          </p>
        </b-col>
        <b-col>
          <p>
            Ejemplo: Un ortopedista puede tener interés Científico-
            investigativo, social-humanitario y mecánico-manual, mientras que un
            médico esteticista puede tener social-humanitario,
            científico-investigativo y artístico-creativo. Por tanto, elige la
            carrera que sea más afín a tus tres intereses personales.
          </p>
        </b-col>
      </b-row>
      <b-row align-h="end" class="text-center">
        <b-col cols="8" sm="6" md="4"> <button v-if="counter == 42" class="btn btn-hover mt-3 text-center"
            @click="reloadPage">
            Iniciar Nuevo Test
          </button></b-col>
      </b-row>

    </b-container>
    <!-- Alerta de advertencia -->
    <b-container class="mt-4">
      <b-alert :show="dismissCountDown" dismissible fade variant="warning" @dismiss-count-down="countDownChanged">
        Debes responder todas las preguntas antes de continuar.
      </b-alert>
    </b-container>

  </section>
</template>
<script>
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import { core } from "../../../config/pluginInit"
import { QUESTIONS, QUESTIONS_2, QUESTIONS_3 } from "./const"
import Resultado from "./Resultado"

const db = firebase.firestore()

export default {
  name: "TestVocacional",
  components: {
    Resultado
  },
  mounted() {
    this.$ga.page("/test-profesional")
    core.index()
    this.userInfo = this.$store.getters["Setting/authUserState"]
    console.log(this.userInfo)
    if (this.userInfo.user.authEmail) {
      this.userFirebaseInfo = this.userInfo.user
    }
  },
  data() {
    return {
      userFirebaseInfo: {},
      userInfo: {},
      carrerasArray: [
        { letra: ["r", 0] },
        { letra: ["i", 0] },
        { letra: ["a", 0] },
        { letra: ["s", 0] },
        { letra: ["r", 0] },
        { letra: ["c", 0] }
      ],
      select: null,
      optionsV: [
        { value: 0, text: "Seleccione una opción" },
        { value: 20, text: "1. Muy bajo nivel de desarrollo" },
        { value: 40, text: "2. Bajo nivel de desarrollo" },
        { value: 60, text: "3. Medio nivel de desarrollo" },
        { value: 80, text: "4. Alto nivel de desarrollo" },
        { value: 100, text: "5. Muy alto nivel de desarrollo" }
      ],
      optionsV0: [
        { value: 20, text: "Si" },
        { value: 0, text: "No" }
      ],
      optionsV1: [
        { value: 11.11, text: "Si" },
        { value: 0, text: "No" }
      ],
      preguntas: QUESTIONS,

      counter: -1,
      preguntas2: QUESTIONS_2,
      preguntas3: QUESTIONS_3,
      preguntaActual: 0,
      resultados: [
        { resultado: 0 },
        { resultado: 0 },
        { resultado: 0 },
        { resultado: 0 },
        { resultado: 0 },
        { resultado: 0 }
      ],
      dismissSecs: 5,
      dismissCountDown: 0
    }
  },
  watch: {
    // cada vez que la pregunta cambie, esta función será ejecutada
    counter: function () {
      console.log(this.counter)
      if (this.counter === 42) {
        this.respuestas()
        const fecha = this.fechaActual()
        this.guardarDatos(fecha)
      }
    }
  },
  methods: {
    respuestas() {
      const results = [0, 0, 0, 0, 0, 0]

      this.preguntas.forEach((pregunta, index) => {
        const questionSet = Math.floor(index / 5)
        results[questionSet] += pregunta.answer
      })

      this.preguntas3.forEach((questionSet, setIndex) => {
        questionSet.preguntas.forEach((pregunta, index) => {
          results[setIndex] += pregunta.answer
        })
      })

      this.preguntas2.forEach((pregunta, index) => {
        results[index] += pregunta.answer
      })

      this.resultados.forEach((resultado, index) => {
        resultado.resultado += results[index]
      })
    },
    avanzar: function (event) {
      this.counter += 1
    },
    enviarRespuestas: function () {
      /* r=1 , i=2 , a=3 , s=4 , e=5 , c=6 */
      for (let i = 0; i < this.carrerasArray.length; i++) {
        this.carrerasArray[i].letra[1] = (
          (this.resultados[i].resultado / 300) *
          100
        ).toFixed()
      }
      console.log(this.carrerasArray)
      // SE ORDENA LOS RESULTADOS DE MAYOR A MENOR.
      this.carrerasArray.sort((a, b) => b.letra[1] - a.letra[1])
      console.log(this.carrerasArray)
    },
    guardarDatos(fechaDeHoy) {
      const [
        mecanico,
        cientifico,
        artistico,
        sociales,
        negocios,
        administrativas
      ] = this.resultados.map((r) => (r.resultado / 300) * 100)

      db.collection("testVocacional")
        .doc(this.userFirebaseInfo.idNumber)
        .collection(this.userFirebaseInfo.idNumber)
        .doc(fechaDeHoy)
        .set({
          name: this.userFirebaseInfo.name,
          lastName: this.userFirebaseInfo.lastName,
          email: this.userFirebaseInfo.authEmail,
          mecanicoManuales: `${mecanico.toFixed()}%`,
          cientificoInvestigativas: `${cientifico.toFixed()}%`,
          artisticoCreativas: `${artistico.toFixed()}%`,
          socialesHumanitarias: `${sociales.toFixed()}%`,
          negociosEmprendimiento: `${negocios.toFixed()}%`,
          administrativas: `${administrativas.toFixed()}%`,
          date: fechaDeHoy
        })
        .then(() => {
          console.log("Document successfully written!")
        })
        .catch((error) => {
          console.error("Error writing document: ", error)
        })
    },
    fechaActual() {
      const date = new Date()
      const output =
        String(date.getDate()).padStart(2, "0") +
        "-" +
        String(date.getMonth() + 1).padStart(2, "0") +
        "-" +
        date.getFullYear() +
        "  Hora " +
        date.getHours() +
        ":" +
        date.getMinutes() +
        ":" +
        date.getSeconds()
      return output
    },
    reloadPage() {
      window.location.reload()
    },
    nextQuestion(answer) {
      if (answer) {
        this.counter++
      } else {
        this.dismissCountDown = this.dismissSecs
      }
    },
    // alert warning
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    }
  }
}
</script>
<style scoped>
.btn-circle {
  width: 4em;
  height: 4em;
  border-radius: 50% !important;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  color: black;
}

.btn-circle:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.b-container-background {
  background: var(--iq-bg1) !important;
}
</style>
